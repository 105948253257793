<template>
  <PracticePlayPage
    :image="category.image"
    :title="category.name[$i18n.locale]"
    :description="category.description[$i18n.locale]"
  >
    <v-row justify="center">
      <v-col
        class="ma-3 mb-10"
        cols="auto"
        v-for="(s, index) in satsangInterpretationData"
        :key="s.name + index"
      >
        <v-card rounded class="card">
          <div class="card__playerBox">
            <div style="padding: 75% 0 0 0; position: relative">
              <iframe
                :src="`https://player.vimeo.com/video/${s.video}?portrait=0&byline=0`"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <v-card-title
            class="text-subtitle-1 font-weight-medium card__cardTitle"
            >{{ s.name[$i18n.locale] }}</v-card-title
          >
          <v-card-text>
            <router-link
              :to="'/practice/satsang-interpretation/' + s.id"
              class="menuLink"
            >
              <v-btn color="primary" class="mr-2" rounded>Quiz</v-btn>
            </router-link>
            <a target="_blank" :href="s.pdf">
              <v-btn color="primary" rounded>PDF</v-btn>
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </PracticePlayPage>
</template>

<script>
import PracticePlayPage from '../components/common/PracticePlayPage.vue'
import Player from '../components/Player.vue'

export default {
  components: {
    PracticePlayPage,
    Player
  },
  computed: {
    category() {
      return this.$store.state.practicePlayCategories.find(
        (c) => c.id == this.id
      )
    },
    satsangInterpretationData() {
      return this.$store.state.practicePlayPages.filter(
        (p) => p.categoryId === this.id
      )
    }
  },
  data() {
    return {
      id: 'satsang-interpretation'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/global.scss';

.col:nth-of-type(3n + 1) ::v-deep .wrapper .wrapper__card {
  background-image: linear-gradient(to top right, #cdd86c, #64c668) !important;
}
.col:nth-of-type(3n + 2) ::v-deep .wrapper .wrapper__card {
  background-image: linear-gradient(to top right, #6cbfd8, #64c668) !important;
}
.col:nth-of-type(3n + 3) ::v-deep .wrapper .wrapper__card {
  background-image: linear-gradient(to top right, #d16cd8, #64c668) !important;
}
.card {
  width: 330px;
  height: 380px;
  z-index: 0;
  box-shadow: 0 1.5rem 4rem rgb(0 0 0 / 20%) !important;
  transform-style: preserve-3d;
  transition: all 0.2s;

  &::before {
    content: '';
    height: 31%;
    width: 98%;
    position: absolute;
    bottom: -5px;
    right: -5px;
    z-index: -1;
    background-color: #ea2690;
    transform: translateZ(-1px) rotate(10deg);
    border-radius: 10px;
  }

  &:hover {
    transform: scale(1.01) translateY(-10px);
  }

  &__playerBox {
    width: 100%;
    height: 250px;
    background-color: black;

    &-player {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__cardTitle {
    height: 80px;
  }

  ::v-deep .plyr {
    border-radius: 0px !important;
  }
}

.row {
  .col:nth-of-type(2n + 1) .card::before {
    background-color: #ead026;
  }
  .col:nth-of-type(3n + 1) .card::before {
    background-color: #ea7e26;
  }
}
</style>
