<template>
  <PracticePlayPage
    :image="nishchayData.image"
    :title="nishchayData.name[$i18n.locale]"
    :description="nishchayData.description[$i18n.locale]"
  >
    <v-row justify="center">
      <v-col cols="12" sm="6" class="d-flex align-start">
        <v-carousel v-model="model" hide-delimiters height="auto">
          <v-carousel-item
            v-for="(n, i) in Array.from(Array(9).keys())"
            :key="i"
            :src="calculatedImageId(i + 1)"
            contain
          >
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </PracticePlayPage>
</template>

<script>
import PracticePlayPage from '../components/common/PracticePlayPage.vue'
import Utils from '../util/Utils'
export default {
  components: {
    PracticePlayPage
  },
  computed: {
    nishchayData() {
      return this.$store.state.practicePlayCategories.find(
        (p) => p.id === this.nishchayId
      )
    },
    nishchayId() {
      return 'nishchay'
    }
  },
  data: () => ({}),
  methods: {
    calculatedImageId(url) {
      return Utils.imgUrlByPath(`nishchay/Nishchay-${url}.jpg`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/global.scss';

.prayerText {
  text-align: center;
  font-style: italic;
  color: black !important;
}

::v-deep .plyr {
  border-radius: 0px !important;
  border-top-left-radius: 25px !important;
}
.card {
  width: 300px;
  height: 320px;
  z-index: 0;
  transform-style: preserve-3d;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.01) translateY(-10px);
  }

  &__imageBox {
    position: relative;
    .v-image {
      opacity: 0.8;
      border-top-left-radius: 25px;
    }
  }

  &__title {
    height: 80px;
  }

  &__actionBox {
    position: absolute;
    bottom: 5px;
    padding: 5px;
    padding-left: 10px;
  }

  &__prayerVideo {
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: black;
    border-radius: 40px;
    animation: pulse-dark 2s infinite;
  }

  @keyframes pulse-dark {
    0% {
      box-shadow: 0 0 0 0 rgb(76 60 60 / 70%);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(76, 60, 60, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(76, 60, 60, 0);
    }
  }
}
</style>
